<template>
  <div class="page page-project-apply">
    <div class="fit v-pa-md v-mx-auto" style="max-width: 1000px">
      <template v-if="!applied">
        <h2 class="text-center text-primary">填报项目申请书</h2>
        <!-- <a-divider /> -->
        <div class="v-pa-lg v-my-lg">
          <a-steps progress-dot :current="currentStep" size="small">
            <a-step title="提交项目意向" />
            <a-step title="千禾评审" />
            <a-step title="提交项目申请书" />
            <a-step title="签订资助协议" />
            <a-step title="项目资助" />
            <a-step title="项目结项" />
            <a-step title="完成" />
          </a-steps>
        </div>
        <div class="panel v-mx-auto" style="max-width: 1000px">
          <!-- <div class="panel-info-section">
            <div class="panel-info-section-title">
              <a-icon type="caret-right" class="text-primary v-mr-xs" />
              <span class="text-bold">项目意向书</span>
            </div>
            <div class="table-container">
              <a-form-model :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
                <a-row :gutter="0">
                  <a-col span="12">
                    <a-form-model-item :colon="false" label="所属版块">
                      <span>{{ data.subject }}</span>
                    </a-form-model-item>
                  </a-col>
                  <a-col span="12">
                    <a-form-model-item :colon="false">
                      <span slot="label">议题名称</span>
                      <span>{{ data.topic }}</span>
                    </a-form-model-item>
                  </a-col>
                  <a-col span="24">
                    <a-form-model-item :colon="false" label="项目概述" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                      <span>{{ data.summary }}</span>
                    </a-form-model-item>
                  </a-col>
                  <a-col span="24">
                    <a-form-model-item :colon="false" label="相关文件" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                      <div class="file-list" v-if="data.$201Files">
                        <div class="file-item" v-for="file in data.$201Files" :key="file.id">
                          <span class="text-indigo hover-line">{{ file.fileOriginName }}</span>
                        </div>
                      </div>
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </a-form-model>
            </div>
          </div> -->
          <div class="v-mx-auto" v-if="data.checkResult === 0" style="max-width: 900px">
            <a-alert class="v-mb-sm" type="error">
              <div slot="message" class="text-red">
                <div class="text-red font-md"><a-icon type="exclamation-circle" class="v-mr-xs" />上次提交的项目申请书已被驳回</div>
                <div class="flex v-mt-xs">
                  <span class="text-right text-grey">审核意见：</span>
                  <span class="col">{{ data.checkOpinion }}</span>
                </div>
                <div class="flex">
                  <span class="text-right text-grey">修改批注见附件：</span>
                  <div class="col" style="margin-top: 3px">
                    <file-container :files="data.$203Files" :belongId="id" fileSign="P01" v-if="data.$203Files && id"></file-container>
                  </div>
                </div>
              </div>
            </a-alert>
          </div>
          <div class="panel-body">
            <a-form-model ref="form" :model="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }">
              <a-row :gutter="0">
                <a-col span="12">
                  <a-form-model-item :colon="false">
                    <span slot="label">
                      <i class="required-mark">*</i>
                      项目名称
                    </span>
                    <a-input v-model="form.projectName" ref="projectName" placeholder="项目名称" />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="0">
                <a-col span="12">
                  <a-form-model-item :colon="false" prop="subject">
                    <span slot="label">
                      <i class="required-mark">*</i>
                      项目周期
                    </span>
                    <a-range-picker v-model="form.dates" format="YYYY-MM-DD" value-format="YYYY-MM-DD" :placeholder="['开始时间', '结束时间']" ref="datePicker" style="width: 210px" />
                  </a-form-model-item>
                </a-col>
                <a-col span="12">
                  <a-form-model-item :colon="false" prop="applyAmount">
                    <span slot="label">
                      <i class="required-mark">*</i>
                      拟申请金额
                    </span>
                    <a-input v-model="form.applyAmount" type="number" ref="applyAmount" suffix="元" placeholder="金额" />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="0">
                <a-col span="12">
                  <a-form-model-item :colon="false" prop="supervisor">
                    <span slot="label">
                      <i class="required-mark">*</i>
                      项目负责人
                    </span>
                    <a-input v-model="form.supervisor" ref="supervisor" placeholder="姓名" />
                  </a-form-model-item>
                </a-col>
                <a-col span="12">
                  <a-form-model-item :colon="false" prop="supervisorPost">
                    <span slot="label">
                      <i class="required-mark">*</i>
                      项目负责人职位
                    </span>
                    <a-input v-model="form.supervisorPost" ref="supervisorPost" placeholder="职位" />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="0">
                <a-col span="12">
                  <a-form-model-item :colon="false" prop="supervisorPhone">
                    <span slot="label">
                      <i class="required-mark">*</i>
                      联系电话
                    </span>
                    <a-input v-model="form.supervisorPhone" ref="supervisorPhone" placeholder="手机号码" />
                  </a-form-model-item>
                </a-col>
                <a-col span="12">
                  <a-form-model-item :colon="false" prop="supervisorEmail">
                    <span slot="label">
                      <i class="required-mark">*</i>
                      联系邮箱
                    </span>
                    <a-input v-model="form.supervisorEmail" ref="supervisorEmail" placeholder="邮箱地址" />
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="0">
                <a-col span="24">
                  <a-form-model-item :colon="false" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }" prop="addr">
                    <span slot="label">
                      <i class="required-mark">*</i>
                      项目实施地点
                    </span>
                    <!-- <a-input-group compact>
                      <a-cascader style="width: 250px" expand-trigger="hover" :show-search="{ cascaderFilter }" :options="districts" placeholder="选择地区" v-model="form.districts" ref="districts" />
                      <a-input style="width: calc(100% - 250px)" placeholder="详细地址" allowClear v-model="form.addr" ref="address" />
                    </a-input-group> -->
                    <template v-if="addressList.length > 0">
                      <div class="flex items-center" v-for="(item, index) in addressList" :key="index">
                        <div style="width: calc(100% - 100px)">
                          <a-input-group compact>
                            <a-cascader changeOnSelect style="width: 320px" expand-trigger="hover" :show-search="{ cascaderFilter }" :options="districts" placeholder="选择地区" v-model="item.districts" :ref="`districts-${index}`" />
                            <a-input style="width: calc(100% - 330px)" placeholder="详细地址" allowClear v-model="item.addr" :ref="`addr-${index}`" />
                          </a-input-group>
                        </div>
                        <a-space>
                          <a-button size="small" type="primary" icon="plus" @click="onAddAddress(index + 1)"></a-button>
                          <a-button size="small" type="default" icon="close" @click="onRemoveAddress(index)" v-if="addressList.length > 1"></a-button>
                        </a-space>
                      </div>
                    </template>
                    <a-button size="small" type="primary" icon="plus" @click="onAddAddress(0)" v-else>添加实施地点</a-button>
                  </a-form-model-item>
                </a-col>
                <a-col span="24">
                  <a-form-model-item :colon="false" prop="content" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
                    <span slot="label">
                      <i class="required-mark">*</i>
                      项目介绍
                    </span>
                    <a-textarea v-model="form.content" placeholder="项目介绍" :rows="8" style="width: 100%" ref="content" />
                  </a-form-model-item>
                </a-col>
                <a-col span="24">
                  <a-form-model-item :colon="false" prop="files" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
                    <span slot="label">
                      <i class="required-mark">*</i>
                      项目申请书/预算表
                    </span>
                    <!-- <div class="font-xs v-my-xs lineh-14 text-grey text-justify">上传项目意向书等文件：</div> -->
                    <a-upload-dragger
                      :action="`${baseURL}/file/upload`"
                      :data="{
                        params: JSON.stringify({ fileBelongId: id, fileType: '202', fileSign: 'P01' }),
                      }"
                      :show-upload-list="{
                        showPreviewIcon: true,
                        showRemoveIcon: true,
                        showDownloadIcon: true,
                      }"
                      :file-list="fileList"
                      :before-upload="beforeUploadFile"
                      @change="handleUploadChange"
                      @preview="handlePreviewFile"
                      @download="({ fileId, name }) => downloadFile({ fileId, belongId: id, fileSign: 'P01', fileName: name })"
                      accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .pdf, .rar, .zip, .ppt, .pptx, .xls, .xlsx"
                    >
                      <div class="v-pa-xs">
                        <a-icon type="plus" />
                        <p class="font-xs">添加文件</p>
                      </div>
                    </a-upload-dragger>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-form-model-item :colon="false" prop="userType">
                <span slot="label"></span>
                <a-space>
                  <a-button type="default" icon="check" @click="onSubmit(false)" :loading="saving">保存</a-button>
                  <a-button type="primary" icon="arrow-right" @click="onSubmit(true)" :loading="saving">提交至千禾审核</a-button>
                  <a-button type="default" icon="rollback" @click="$router.go(-1)">返回</a-button>
                </a-space>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="bg-white" style="width: 600px; margin: 0 auto">
          <a-result status="success" title="项目申报书提交成功!" :sub-title="`请等待千禾审核，审核结果将通过短信发至您的手机${userinfo.phone}`">
            <template #extra>
              <a-button key="view" type="primary" @click="$router.push(`view?id=${id}`)">查看项目</a-button>
              <a-button key="list" @click="$router.push('list?tab=unstarted')">返回项目列表</a-button>
            </template>
          </a-result>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { declareProject, getProject } from '@/api/main'
import districts from '@/utils/districts'
const form = {
  id: null,
  projectName: '',
  applyAmount: '',
  supervisor: '',
  supervisorPost: '',
  supervisorPhone: '',
  supervisorEmail: '',
  dates: [],
  districts: [],
  addr: '',
  content: '',
}
export default {
  name: 'ProjectApply',
  data() {
    return {
      id: +this.$route.query.id,
      currentStep: 2,
      applied: false,
      form,
      districts,
      fileList: [],
      addressList: [],
      data: {},
      saving: false,
    }
  },
  computed: {
    subjects: vm => vm.$store.state.app.subjects,
  },
  methods: {
    load() {
      let id = +this.$route.query.id
      getProject(id).then(({ data }) => {
        if (data.success) {
          this.data = data.result || {}
          let { id, projectName, addresses, startDate, endDate, applyAmount, supervisor, supervisorPost, supervisorPhone, supervisorEmail, content, files } = data.result
          this.data.$203Files = files.filter(file => file.fileType === '203')
          this.fileList = files
            .filter(file => file.fileType === '202')
            .map(file => ({
              uid: file.id,
              fileId: file.id,
              name: file.fileOriginName,
              status: 'done',
              belongId: id,
              fileSign: 'P01',
            }))
          if (addresses.length > 0) {
            this.addressList = addresses.map(({ id, province, city, district, addr }) => {
              let districts = []
              if (province) {
                districts.push(province)
              }
              if (city) {
                districts.push(city)
              }
              if (district) {
                districts.push(district)
              }
              return {
                id,
                districts,
                addr,
              }
            })
          } else {
            this.onAddAddress()
          }
          let dates = []
          if (startDate && endDate) {
            dates = [startDate, endDate]
          }

          let form = {
            id,
            projectName: projectName || '',
            districts,
            dates,
            applyAmount: typeof applyAmount === 'number' ? applyAmount : '',
            supervisor: supervisor || '',
            supervisorPost: supervisorPost || '',
            supervisorPhone: supervisorPhone || '',
            supervisorEmail: supervisorEmail || '',
            content: content || '',
          }
          this.form = form
        }
      })
    },
    handleUploadChange(info) {
      this.fileList = this.handleUploadFile(info, -1)
    },
    onAddAddress(index = 0) {
      let len = this.addressList.length
      this.addressList.splice(index, 0, {
        seq: len + 1,
        districts: [],
        addr: '',
      })
    },
    onRemoveAddress(index) {
      this.addressList.splice(index, 1)
    },
    isModified() {
      let form = this.form
      let data = this.data
      if (
        form.id === data.id &&
        form.projectName.trim() === data.projectName.trim() &&
        form.applyAmount === data.applyAmount &&
        form.supervisor.trim() === data.supervisor.trim() &&
        form.supervisorPost.trim() === data.supervisorPost.trim() &&
        form.supervisorPhone.trim() === data.supervisorPhone.trim() &&
        form.supervisorEmail.trim() === data.supervisorEmail.trim() &&
        form.content.trim() === data.content.trim()
      ) {
        let originFileIds = data.files.filter(file => file.fileType === '202').map(file => file.id)
        let fileIds = this.fileList.map(file => file.fileId)
        if (originFileIds.length != fileIds.length) {
          return true
        }
        if (fileIds.some(id => !originFileIds.includes(id))) {
          return true
        }
        let originAddresses = data.addresses
        let addresses = this.addressList.filter(item => item.districts[0])
        if (originAddresses.length != addresses.length) {
          return true
        }
        if (addresses.some(item => !originAddresses.some(({ province, city, district, addr }) => (item.districts[0] || '') == (province || '') && (item.districts[1] || '') == (city || '') && (item.districts[2] || '') == (district || '') && (addr || '').trim() == (item.addr || '').trim()))) {
          return true
        }
        return false
      }
      return true
    },
    handleSubmit(nextStep) {
      let { id, projectName, dates, applyAmount, supervisor, supervisorPost, supervisorPhone, supervisorEmail, content } = this.form
      let { fileList } = this
      let addresses = this.addressList.filter(item => item.districts[0])
      let fileIds = fileList.map(file => file.fileId)
      let params = {
        id,
        projectName,
        startDate: dates[0],
        endDate: dates[1],
        applyAmount,
        supervisor,
        supervisorPost,
        supervisorPhone: supervisorPhone.trim(),
        supervisorEmail: supervisorEmail.trim(),
        content,
        fileIds,
        addresses: addresses.map(({ id, districts, addr }) => ({ id, province: districts[0], city: districts[1], district: districts[2], addr })),
        nextStep: nextStep ? 1 : 0,
      }
      console.log(params)
      this.saving = true
      declareProject(params)
        .then(({ data }) => {
          if (data.success) {
            this.$message.success('保存成功')
            if (nextStep) {
              this.applied = true
            } else {
              this.$router.push('list?tab=unstarted')
            }
          } else {
            this.$message.warning(data.msg)
          }
          this.saving = false
        })
        .catch(err => {
          if (err) {
            this.$message.warning(err)
          }
          this.saving = false
        })
    },
    onSubmit(nextStep) {
      let vm = this
      let { id, projectName, dates, applyAmount, supervisor, supervisorPost, supervisorPhone, supervisorEmail, content } = this.form
      let { fileList } = this
      let addresses = this.addressList.filter(item => item.districts[0])
      this.$message.destroy()
      if (projectName.trim() === '') {
        this.$refs.projectName.focus()
        this.$message.warning('输入项目名称')
        return
      }
      if (addresses.length === 0) {
        this.$message.warning('请选择项目实施地点')
        if (this.$refs['districts-0'] && this.$refs['districts-0'][0]) {
          this.$refs['districts-0'][0].focus()
        }
        return
      }
      if (dates.length === 0) {
        this.$refs.datePicker.focus()
        this.$message.warning('请选择项目周期')
        return
      }
      if (!applyAmount) {
        this.$refs.applyAmount.focus()
        this.$message.warning('请输入拟投资金额')
        return
      }
      if (supervisor.trim() === '') {
        this.$refs.supervisor.focus()
        this.$message.warning('请输入项目负责人')
        return
      }
      if (supervisorPost.trim() === '') {
        this.$refs.supervisorPost.focus()
        this.$message.warning('请输入项目负责人职位')
        return
      }
      if (supervisorPhone.trim() === '') {
        this.$refs.supervisorPhone.focus()
        this.$message.warning('请输入负责人联系电话')
        return
      }
      if (!this.$utils.isValidPhone(supervisorPhone.trim())) {
        this.$message.warning('请输入正确的手机号码')
        this.$refs.supervisorPhone.focus()
        return
      }
      if (supervisorEmail.trim() === '') {
        this.$refs.supervisorEmail.focus()
        this.$message.warning('请输入项目负责人邮箱')
        return
      }
      if (!this.$utils.isValidEmail(supervisorEmail.trim())) {
        this.$refs.supervisorEmail.focus()
        this.$message.warning('邮箱格式不正确')
        return
      }
      if (content.trim() === '') {
        this.$refs.content.focus()
        this.$message.warning('请输入项目介绍')
        return
      }
      if (fileList.length === 0) {
        this.$message.warning('请上传相关文件')
        return
      }
      if (this.data.checkResult === 0 && !this.isModified()) {
        this.$confirm({
          icon: 'info-circle',
          title: '您未进行任何改动，是否确认提交？',
          okText: '提交',
          cancelText: '关闭',
          onOk() {
            vm.handleSubmit(nextStep)
          },
        })
      } else {
        this.handleSubmit(nextStep)
      }
    },
  },
  created() {
    if (this.$route.query.id) {
      this.load()
    }
  },
}
</script>
