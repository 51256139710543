import request from '@/utils/request'

const http = request()

export const getProjects = params => http.post('/fe/projects', params)

export const getTasks = () => http.post('/fe/tasks')

export const getReports = () => http.post('/fe/reports')

export const getCheckedReports = params => http.post('/fe/reports/checked', params)

export const getTodoReports = () => http.post('/fe/reports/unchecked')

export const getProject = id => http.post('/project/funded/get', { id })

export const applyProject = params => http.raw(params.nextStep ? '/project/funded/apply/submit' : '/project/funded/apply/save', params)

export const declareProject = params => http.raw(params.nextStep ? '/project/funded/declare/submit' : '/project/funded/declare/save', params)

export const listReports = id => http.post('/project/funded/report/list', { id })

export const uploadReport = params => http.post('/project/funded/report/upload', params)

export const saveChangeinfo = params => http.raw(params.check ? '/changinfo/submit' : '/changinfo/save', params)

export const listChangeinfo = projectId => http.post('/changinfo/list', { projectId })

export const delChangeinfo = id => http.post('/changinfo/delete', { id })

export const listTopic = params => http.post('/project/topic/list-fe', params)

export const listTemplate = params => http.post('/file/template/list', params)
